import React from 'react';
import ArticleContentItem from '../slice-zone/slices/article-content/article-content-item';

export default function ArticleBodyBlocked ({ body }) {
  const { items } = body;
  const item = items[0];

  return (
    <section className="article-slice article-content article-blocked">
      <ArticleContentItem data={item} blocked />
    </section>
  );
}
