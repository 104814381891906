/*globals process*/
import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { withPrismicUnpublishedPreview, componentResolverFromMap }
  from 'gatsby-plugin-prismic-previews';
import { Img } from '../components/image';
import useMergePrismicPreviewData from '../utils/use-merge-prismic-preview-data-fix';
import PageTemplate from '../templates/page';
import ArticleTemplate from '../templates/article';
import PoseTemplate from '../templates/pose';
import { TextButton } from '../components/buttons';
import { linkResolver } from '../utils/linkResolver';
import '../styles/404page.scss';

function NotFoundPage () {
  const _data = useStaticQuery(graphql`
    query the404PageQuery {
      prismic404Page {
        _previewable
        data {
          image { gatsbyImageData, alt }
          content { html }
          cta_text
          cta_link { url }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(_data);

  const { image, content, cta_text, cta_link } = data.prismic404Page.data;

  return (
    <>
      <Helmet>
        <title>Not found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="container the404page">
        <Img image={image} alt={image.alt} />
        <div className="content" dangerouslySetInnerHTML={{ __html: content.html }} />
        <TextButton to={cta_link.url} className="cta">{cta_text}</TextButton>
      </div>

      <div className="the404page-bkg"></div>
    </>
  );
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PageTemplate,
      article: ArticleTemplate,
      pose: PoseTemplate
    })
  }
]);
