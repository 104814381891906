/* globals process */
import React from 'react';
import { Helmet } from 'react-helmet';
import ArticleBody from '../components/article-body';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from '../utils/linkResolver';
import { graphql } from 'gatsby';

function ArticleTemplate ({ data, pageContext }) {
  const doc = data.prismicArticle.data;

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.meta_description} />
        <meta name="og:description" content={doc.meta_description} />
        <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}${pageContext.url}`} />
        {doc.members_only && (<meta name="robots" content="noindex, nofollow" />)}
      </Helmet>
      <ArticleBody
        data={doc}
      />
    </>
  );
}

export default withPrismicPreview(ArticleTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver
  }
]);

export const query = graphql`
query ArticleQuery($id: String) {
  site {
    siteMetadata {
      siteUrl
    }
  }

  prismicArticle(id: { eq: $id }) {
    _previewable
    data {
      title { text }
      meta_description
      author_description {
        html
      }
      author_image {
        gatsbyImageData
      }
      author_name
      members_only
      publish_date(formatString: "MMMM DD, YYYY")
      read_time
      subtitle {
        html
      }

      body {
        __typename
        ... on PrismicArticleDataBodyArticleContent {
          ... ArticleContentFields
        }

        ... on PrismicArticleDataBodyArticleDownloads {
          ... ArticleDownloadsFields
        }

        ... on PrismicArticleDataBodyArticleVideo {
          ... ArticleMediaFields
        }

        ... on PrismicArticleDataBodyArticlesList {
          ... ArticlesListFields
        }

        ... on PrismicArticleDataBodyCustomCode {
          ... ArticleBodyCustomCodeFields
        }
      }

      body1 {
        __typename
        ... on PrismicArticleDataBody1ComingSoonArticleFeature {
          ... ArticleComingSoonArticleFields
        }
        ... on PrismicArticleDataBody1FeaturedTestimonial {
          ... ArticleFeaturedTestimonialFields
        }
        ... on PrismicArticleDataBody1SubscriptionPricing {
          ... ArticleSubscriptionPricingFields
        }
        ... on PrismicArticleDataBody1ThreeColCta {
          ... ArticleThreeColCtaFields
        }
        ... on PrismicArticleDataBody1TwoColumnSection {
          ... ArticleTwoColumnSectionFields
        }
        ... on PrismicArticleDataBody1CustomCode {
          ... ArticleBody1CustomCodeFields
        }
      }
    }
  }
}
`;
