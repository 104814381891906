import React, { useContext } from 'react';
import { Img } from '../image';
import ArticleBodyBlocked from './article-body-blocked';
import { IconFacebook, IconTwitter, IconLinkedIn, IconPrint, IconPinterest } from '../icons';
import SliceZone from '../slice-zone';
import { Link } from 'gatsby';
import { AuthContext } from '../auth';
import './styles.scss';

export default function ArticleBody ({ data }) {
  const auth = useContext(AuthContext);

  const {
    body,
    body1,
    author_description,
    author_image,
    author_name,
    members_only,
    publish_date,
    read_time,
    title,
    subtitle
  } = data;

  const handlePrintClick = () => {
    window.print();
  };

  let locationHref = '';
  if (typeof window !== 'undefined') {
    locationHref = window.location.href;
  }

  return (
    <section className="article-body">
      <div className="article-body__inner container">
        <Link className="back-to-resources" to="/resources"><span className="chev-left">&lsaquo;</span> Resources</Link>
        <div className="article-heading">
          <h1 className="article-title">{title.text}</h1>
          {
            subtitle?.html ? (
              <div
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: subtitle.html }}
              />
            ) : null
          }
        </div>

        <div className="content-container">
          <div className="content-slices">
            {
              (members_only && !auth.subscription?.active) ? (
                <ArticleBodyBlocked body={body.find(a => a.slice_type === 'article_content')} />
              ) : <SliceZone sliceZone={body} />
            }
          </div>
          <div className="article-sidebar">
            <div className="author-container">
              {
                author_image?.gatsbyImageData ? (
                  <div className="author-image">
                    <Img image={author_image} />
                  </div>
                ) : null
              }
              {
                author_name ? (
                  <span className="author-name">
                    By <span className="name-text body-large">{author_name}</span>
                  </span>
                ) : null
              }
            </div>
            <div
              className="author-description"
              dangerouslySetInnerHTML={{ __html: author_description.html }}
            />
            <span className="metadata publish-date">{publish_date}</span>
            <span className="readtime">{read_time}</span>
            <div className="share-buttons">
              <span className="metadata">Share</span>
              <div className="buttons">
                <a href={`https://www.facebook.com/sharer.php?u=${locationHref}`} target="_blank" rel="noreferrer" aria-label="Share on Facebook">
                  <IconFacebook />
                </a>
                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${locationHref}`} target="_blank" rel="noreferrer" aria-label="Share on LinkedIn">
                  <IconLinkedIn />
                </a>
                <a href={`https://pinterest.com/pin/create/button/?url=${locationHref}&description=${title.text}`} target="_blank" rel="noreferrer" aria-label="Share on Pinterest">
                  <IconPinterest />
                </a>
                <a
                  className="share-print"
                  role="button"
                  tabIndex="0"
                  onClick={handlePrintClick}
                  onKeyDown={handlePrintClick}
                  aria-label="Print Article"
                >
                  <IconPrint />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="second-body-container">
          <SliceZone sliceZone={body1} />
        </div>
      </div>
    </section>
  );
}
