/*globals process*/
import React from 'react';
import { Helmet } from 'react-helmet';
import SliceZone from '../components/slice-zone';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from '../utils/linkResolver';
import { graphql } from 'gatsby';

const PageTemplate = ({ data, pageContext }) => {
  const doc = data.prismicPage.data;

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.meta_description?.text} />
        <meta name="og:description" content={doc.meta_description?.text} />
        <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}${pageContext.url}`} />
        {doc.index_page === false && (<meta name="robots" content="noindex, nofollow" />)}
        {/*<meta name="og:image" content={ogImage?.fixed?.src} />*/}
      </Helmet>
      <SliceZone sliceZone={doc.body} />
    </>
  );
};

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver
  }
]);

export const query = graphql`
  query PageQuery($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    prismicPage(id: { eq: $id }) {
      _previewable

      data {
        title { text }
        meta_description { text }
        index_page
        body {
          __typename
          ... on PrismicPageDataBodyHeroCarousel {
            ... HeroCarouselFields
          }

          ... on PrismicPageDataBodyCenteredCtaSection {
            ... CenteredCTAFields
          }

          ... on PrismicPageDataBodyThreeColCta {
            ... ThreeColCtaFields
          }

          ... on PrismicPageDataBodyAdvisorsGrid {
            ... AdvisorsGridFields
          }

          ... on PrismicPageDataBodySubscriptionPricing {
            ... SubscriptionPricingFields
          }

          ... on PrismicPageDataBodySubscriptionPricing2 {
            ... SubscriptionPricing2Fields
          }

          ... on PrismicPageDataBodyFeaturedTestimonial {
            ... FeaturedTestimonialFields
          }

          ... on PrismicPageDataBodyComingSoonArticleFeature {
            ... ComingSoonArticleFields
          }

          ... on PrismicPageDataBodyVideoEmbed {
            ... VideoEmbedFields
          }

          ... on PrismicPageDataBodyTwoColumnSection {
            ... TwoColumnSectionFields
          }

          ... on PrismicPageDataBodyFeaturedArticlesList {
            ... FeaturedArticlesListFields
          }

          ... on PrismicPageDataBodyArticleArchiveList {
            ... ArticleArchiveListFields
          }

          ... on PrismicPageDataBodyInteractivePoseFeature {
            ... InteractivePoseFeatureFields
          }

          ... on PrismicPageDataBodyCustomCode {
            ... CustomCodeFields
          }
        }
      }
    }
  }
`;
